import React from "react";
import classes from './Premice.module.css'
import photo from '../../../images/fond_header.jpg'

export default function Premice() {
    const taille = {
        width: '100%',
        height: window.innerHeight-80+'px',
        zIndex: "1",
    }

    return (
        <div className={classes.premice} style={taille}></div>
    )
}