import React, { useState } from "react";
import classes from './Skills.module.css'

import HardS from "./HardS/HardS";
import SoftS from "./SoftS/SoftS";

//images
import logoHtml from '../../../images/logo/html5.png'
import logoCss from '../../../images/logo/css3.png'
import logoPhp from '../../../images/logo/php.png'
import logoMySql from '../../../images/logo/mysql.png'
import logoJs from '../../../images/logo/js.png'
import logoReact from '../../../images/logo/react.png'
import logogTS from '../../../images/logo/typescript.png'
import logoTW from '../../../images/logo/tailwindcss.png'
import logoNode from '../../../images/logo/nodeJS.png'
import logoSymfony from '../../../images/logo/symfony.png'
import logoPython from '../../../images/logo/python.png'
import logoJava from '../../../images/logo/java.png'
import logoC from '../../../images/logo/c.png'
import logoGH from '../../../images/logo/github.png'
import logoFigma from '../../../images/logo/figma.png'
import logoWordP from '../../../images/logo/wordpress.png'
import iconTeam from '../../../images/logo/teamwork.png'
import iconTime from '../../../images/logo/time.png'
import iconCom from '../../../images/logo/communication.png'
import iconOrga from '../../../images/logo/orga.png'
import iconAdapt from '../../../images/logo/adapt.png'

//pdf
import cv from '../../../images/CV_Aurelien_Lefebvre.pdf'
import cv_Motiv from '../../../images/CV_Motivationnel_Aurelien_Lefebvre.pdf'

function Skills(params) {
    '/'
    const [skill, setSkill] = useState({
        hard: [
            {
                nom: 'HTML5',
                img: logoHtml,
                degres: 8,
                startLearn: false,
            },
            {
                nom: 'CSS3',
                img: logoCss,
                degres: 7,
                startLearn: false,
            },
            {
                nom: 'PHP',
                img: logoPhp,
                degres: 7,
                startLearn: false,
            },
            {
                nom: 'MySql',
                img: logoMySql,
                degres: 7,
                startLearn: false,
            },
            {
                nom: 'JS',
                img: logoJs,
                degres: 6.5,
                startLearn: false,
            },
            {
                nom: 'React',
                img: logoReact,
                degres: 5,
                startLearn: false,
            },
            {
                nom: 'TypeScript',
                img: logogTS,
                degres: 4,
                startLearn: false,
            },
            {
                nom: 'Tailwind',
                img: logoTW,
                degres: 5,
                startLearn: true,
            },
            {
                nom: 'NodeJS',
                img : logoNode,
                degres: 2,
                startLearn: true,
            },
            {
                nom: 'Synfony',
                img: logoSymfony,
                degres: 6,
                startLearn: false,
            },
            {
                nom: 'Python',
                img: logoPython,
                degres: 6.5,
                startLearn: false,
            },
            {
                nom: 'Java',
                img: logoJava,
                degres: 6,
                startLearn: false,
            },
            {
                nom: 'C',
                img: logoC,
                degres: 6,
                startLearn: false,
            },
            {
                nom: 'Github',
                img: logoGH,
                degres: 7,
                startLearn: false,
            },
            {
                nom: 'Figma',
                img: logoFigma,
                degres: 3.5,
                startLearn: false,
            },
            {
                nom: 'Wordpress',
                img: logoWordP,
                degres: 4,
                startLearn: false,
            }
        ],
        soft: [
            {
                nom: 'Esprit d\'équipe',
                img: iconTeam
            },
            {
                nom: 'Gestion du temps',
                img: iconTime
            },
            {
                nom: 'Communication',
                img: iconCom
            },
            {
                nom: 'Organisé',
                img: iconOrga
            },
            {
                nom: 'Adaptatibilité',
                img: iconAdapt
            }
        ]
    })

    return (
        <div className={classes.Skills}>
            <div>
                <p className={'title'}>Hard Skills</p>
                <HardS hsk={skill.hard}/>
                <hr className={classes.bar}/>
                <p className={'title'}>Soft Skills</p>
                <SoftS ssk={skill.soft}/>
                <p style={{paddingTop: '30px'}}>
                    <a href={cv} download style={{textDecoration: 'underline', color: 'inherit'}}>Télécharger mon CV</a> et <a href={cv_Motiv} download style={{textDecoration: 'underline', color: 'inherit'}}>mon CV motivationnel</a>
                </p>
            </div>
        </div>
    )
}

export default Skills;