import React from "react";
import Carte from './Carte/Carte'
import ListeProjets from "../../Projets/ListeProjets";
import classes from './Cartes.module.css'
import { Link } from "react-router-dom";
import roads from "../../../config/routes";

export default function Cartes(params) {
    return (
        <>
            <div className={classes.flex}>
                <p className="title">Mes Projets</p>
                <div className={classes.cards}>
                    <div className={classes.card}>
                        <Carte card={ListeProjets[0]}/>
                    </div>
                    <div className={classes.card}>
                        <Carte card={ListeProjets[1]}/>
                    </div>
                    <div className={classes.card}>
                        <Carte card={ListeProjets[2]}/>
                    </div>
                </div>
                <Link to={roads.PROJET} className={classes.lien}>Voir tout les projets</Link>
            </div>
        </>
    )
}