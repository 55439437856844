import React from "react";
import classes from './SoftS.module.css'

export default function SoftS(props){

    const SoftS = {
        margin: '5px 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap'
    }

    const styleImg = {
        height: '30px',
        width: '30px',
        paddingRight: '10px'
    }

    const afficheSoft = props.ssk.map((elem, index) => {
        return (
            <div key={index} className={classes.container}>

                <img src={elem.img} style={styleImg}/>
                {elem.nom}
            </div>
        )
    })

    return (
        <div style={SoftS}>
            {afficheSoft}
        </div>
    )
}