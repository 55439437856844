import React from "react";
import NavItem from "./NavItem/Navitem";
import roads from "../../../config/routes";

import classes from './Navigation.module.css'

function Navigation(params) {
    return (
        <ul className={classes.navig}>
            <NavItem exact lien={roads.HOME}>Accueil</NavItem>
            <NavItem exact lien={roads.PROJET}>Projet</NavItem>
        </ul>
    )
}

export default Navigation;