import React from "react";
import photo from '../../../../images/portfolio-photo.png'
import classes from './CardID.module.css'

export default function CardID(params) {
    return (
        <div className={classes.container}>
            <img src={photo} className={classes.CardID}></img>
        </div>
    )
}