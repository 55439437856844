import React, { useState, useRef, useEffect } from 'react';
import classes from './Affiche.module.css'

const Affiche = (props) => {
  const [width, setWidth] = useState(0);
  const [mouseX, setMouseX] = useState(0);
  const [mouseLeaveDelay, setMouseLeaveDelay] = useState(null);

  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      setWidth(cardRef.current.offsetWidth);
    }
  }, [cardRef]);

  const mousePX = mouseX / width;

  const cardStyle = {
    transform: `rotateY(${mousePX * 30}deg) translate3d(0, 0, 0)`
  };
  
  const cardBgImage = {
    backgroundImage: `url(${props.photos})`
  };

  const handleMouseMove = (e) => {
    setMouseX(e.pageX - cardRef.current.offsetLeft - width / 2);
  };

  const handleMouseEnter = () => {
    clearTimeout(mouseLeaveDelay);
  };

  const handleMouseLeave = () => {
    const delay = setTimeout(() => {
      setMouseX(0);
    }, 1000);
    setMouseLeaveDelay(delay);
  };

  // TRANSFO LA DIV CARDBG EN <IMG SRC> ET MODIF LE CSS EN CONSÉQUENCE PUIS MODIFS LES NOMS
  return (
    <div
      className={classes.cardwrap}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={cardRef}
    >
      <div className={classes.card} style={cardStyle}>
        {/* <img src={photo} className={classes.image}/> */}
        <div className={classes.cardbg} style={{...cardBgImage }}></div>
      </div>
    </div>
  );
};

export default Affiche;
