import React from "react";
import classes from './Footer.module.css'
import { Link } from "react-router-dom";
import roads from "../../config/routes";

export default function Footer(params) {
    return (
        <footer className={classes.Footer}>
            <p>
                © Fait par moi-même. Tout droits réservés. 
                <Link to={roads.MENTIONS}> Mentions légales</Link>
                </p>
        </footer>
    )
}