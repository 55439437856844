import React from "react";

export default function Progress(props) {
    const color = props.pourcent < 4 ? '#D72323' : (props.pourcent < 7 ? '#D65A31' : '#4E9F3D');

    const cadre = {
        height: '15px',
        width: '200px',
        backgroundColor: '#EEEEEE',
        borderRadius: 30,
        position: 'relative'
    }
    
    const progres = {
        height: '15px',
        width: props.pourcent*20,
        backgroundColor: color,
        borderRadius: 10,
        position: 'absolute'
    }

    const txt = {
        position: 'absolute',
        top: '-0.4em',
        left: '40%',
        color: '#535353'
    }


    return (
        <div style={cadre}>
            <div style={progres}></div>
            {props.sl ? <span style={txt}>Débute</span> : null}
        </div>
    )
}