import React, { useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from "react-router-dom";

import roads from './config/routes'
import Home from "./Containers/Home/Home";
import Layout from "./Containers/HOC/Layout/Layout";
import Projets from "./Containers/Projets/Projets";
import Projet from './Containers/Projets/Projet/Projet'
import Mentions from './Components/Mentions/Mentions'

function App() {
  return (
    <>
      <link rel="icon" type="image/png" src={'./logo.png'}/>
      <Layout>
        <Routes>
          <Route exact path={roads.HOME} Component={Home}/>
          <Route exact path={roads.PROJET} Component={Projets}/>
          <Route exact path={roads.PROJET+'/:id'} Component={Projets}/>
          <Route exact path={roads.MENTIONS} Component={Mentions}/>
          <Route path='*' element={<p>404</p>}/>
        </Routes>
      </Layout>
    </>
  );
}

export default App;
