import React from "react";
import Projets from "../../../Projets/Projets";
import classes from './Carte.module.css'
import { Link, useNavigate } from "react-router-dom";
import roads from '../../../../config/routes'
import slugify from "slugify";

export default function Carte(props) {
    const navig = useNavigate()

    const bg = {
        'backgroundImage': `url(${props.card.carte})`,
        'backgroundSize': 'cover',
    }

    return (
        <>
            {/* <img src={props.card.carte} className={classes.img} onClick={()=>navig(roads.PROJET)}/> */}
            {/*
                Refaire les liens vers page projets et #slugify(props.card.nom)
            */}
            <Link to={roads.PROJET+'/#'+props.card.slug
                }
                style={{textDecoration: 'none'}}
            >
                <div className={classes.img} style={bg} onClick={() => navig(roads.PROJET)} id="fond">
                    <span className={classes.titre}>
                        {props.card.nom}
                    </span>
                </div>
            </Link>
        </>
    )
}
