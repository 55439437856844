import React from "react";
import CardID from "./CardID/CardID";
import classes from './Presentation.module.css'

function Presentation(params) {
    return (
        <div className={classes.presentation}>
            <CardID/>
            <div className={classes.texte}>
                <div>
                    Bonjour, je m'appelle Aurélien, <br/>
                    je suis étudiant chez Ynov Campus Lille en mastère Développement Web. <br/>Je suis actuellement en recherche d'alternance afin de dévolopper 
                    mes compétences dans le domaine du web. <br/><br/>
                    N'hésitez pas à télécharger mon CV un peu plus bas et à me contacter pour plus d'informations. <br/><br/>
                    Merci à vous et bonne visite.
                </div>
            </div>
        </div>
    )
}

export default Presentation;