import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import slugify from "slugify";
import ListeProjets from './ListeProjets'
import Projet from "./Projet/Projet";
import classes from './Projets.module.css'

function Projets(params) {
    const location = useLocation();
    console.log(location)

    useEffect(() => {
        if (location.hash) {
            document.querySelector(`${location.hash}`).scrollIntoView({behavior: 'smooth'})
        }else {
            window.scrollTo(0, 0)
        }
    },[])

    const proG = ListeProjets.map((elem, index) => {
        const pos = index%2 === 0 ? 'gauche' : 'droite'

        return (
            <div key={index} className={classes.block} id={elem.slug}>
                <Projet
                    nom={elem.nom}
                    descri={elem.description}
                    lang={elem.langages}
                    lien={elem.lien}
                    photos={elem.photos}
                    position={pos}
                    slug={elem.slug}
                />
            </div>
        )
    })

    return (
        <div className={classes.projets}>
            <p className="title" style={{textAlign: "center"}}>Mes Projets</p>
            {proG}
        </div>
    )
}

export default Projets;