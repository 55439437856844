import React from "react";
import classes from './Input.module.css'

export default function Input(props) {
    let champ;
    let champClass = [classes.champ]

    if (!props.valid && props.tape) {
        champClass.push(classes.invalid)
    }

    switch (props.type) {
        case 'input':
            champ = <input {...props.config} name={props.id} value={props.value} onChange={props.change} id={props.id} className={champClass.join(' ')}/>
            break;
        case 'textarea':
            champ = <textarea value={props.value} {...props.config} name={props.id} onChange={props.change} id={props.id} className={champClass.join(' ')+' '+classes.try}/>
            break;
        default:
            champ = <p>Bug</p>
            break;
    }

    return (
        <div className={classes.input}>
            <label htmlFor={props.id} className={classes.label}>{props.label}</label><br/>
            {champ}

            {!props.valid && props.tape ? <p>{props.msg}</p> : null}
        </div>
    )
}