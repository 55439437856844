import React, { useState } from "react";
import Input from "../../../Components/UI/Input/Input";
import emailjs from '@emailjs/browser'
import classes from './Contact.module.css'

import logoGithub from "../../../images/logo/githubGris.png"
import logoLinkedin from "../../../images/logo/linkedin.png"

function Contact() {
    const [inputs, setInputs] = useState({
        nom: {
            elemType: 'input',
            elemConfig: {
                type: '',
                placeholder: 'Votre nom'
            },
            rules: {
                require: true,
                maxlength: 75
            },
            tape: false,
            value: '',
            label: 'Nom',
            valid : false,
            errorMsg: 'Ce champ doit être renseigné et faire moins de 75 caractères.'
        },
        mail: {
            elemType: 'input',
            elemConfig: {
                type: '',
                placeholder: 'Votre email'
            },
            rules: {
                require: true,
                pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
            },
            tape: false,
            value: '',
            label: 'Email',
            valid : false,
            errorMsg: 'L\'adresse mail doit être valide.'
        },
        message: {
            elemType: 'textarea',
            elemConfig: {
                placeholder: 'Votre message'
            },
            rules: {
                require: true,
            },
            tape: false,
            value: '',
            label: 'Message',
            valid : false,
            errorMsg: 'Ce champ doit être renseigné.'
        }
    })
    const [formValid, setFormValid] = useState(false)

    const envoiMail = e => {
        e.preventDefault()

        const donnees = {
            nom: e.target[0].value,
            mail: e.target[1].value,
            message: e.target[2].value
        }

        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, donnees, process.env.REACT_APP_PUBLIC_KEY).then(response => {
            const newInputs = {...inputs}
            for (const key in newInputs) {
                newInputs[key].value = ''
                newInputs[key].valid = false
                newInputs[key].tape = false
            }
            setInputs(newInputs)
            setFormValid(false)
            alert('Mail bien envoyé')
        })
        .catch(error => {
            alert('Erreur lors de l\'envoie du mail, veuillez réessayer plus tard.')
        });
    }

    const inputValid = (rules, value) => {
        let valid = true

        if (rules.require) {
            valid = value.trim() != '' && valid
        }
        if (rules.minlength) {
            valid = value.length >= rules.minlength && valid
        }
        if (rules.maxlength) {
            valid = value.length <= rules.maxlength && valid
        }
        return valid
    }

    const changeInput = (e, id)=> {
        const newInputs = {...inputs}
        newInputs[id].value = e.target.value
        newInputs[id].tape = true
        newInputs[id].valid = inputValid(inputs[id].rules, newInputs[id].value)
        setInputs(newInputs)

        let valide = true
        for (const key in inputs) {
            valide = inputs[key].valid && valide
        }
        setFormValid(valide)
    }

    const formElem = []
    for (const key in inputs) {
        formElem.push({
            id: key,
            config: inputs[key]
        })
    }

    let form = (
        <form onSubmit={e => envoiMail(e)} className={classes.formulaire}>
            {formElem.map(elem => (
                <Input
                    key={elem.id}
                    id={elem.id}
                    value={elem.config.value}
                    label={elem.config.label}
                    type={elem.config.elemType}
                    config={elem.config.elemConfig}
                    tape={elem.config.tape}
                    valid={elem.config.valid}
                    msg={elem.config.errorMsg}
                    change={(e) => changeInput(e, elem.id)}
                />
            ))}
            <input type="submit" className={classes.btn} disabled={!formValid}/>
        </form>
    )

    return (
        <div className={classes.contact}>
            <p className="title">Contactez-moi</p>
            <div className={classes.container}>
                <a href="https://github.com/AurelienLef"><img src={logoGithub}/></a>
                <a href="https://www.linkedin.com/in/aur%C3%A9lien-lefebvre-6a8bb1239"><img src={logoLinkedin}/></a>
            </div>
            {form}
        </div>
    )
}

export default Contact;