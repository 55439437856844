// Cartes
import cardCC from '../../images/projets/cards/cite_carter.png'
import cardE from '../../images/projets/cards/echecs.png'
import cardMP from '../../images/projets/cards/minis_projets.jpg'

// Affiches
import afficheVide from '../../images/projets/affiches/affiche_vide.png'
import afficheCC from '../../images/projets/affiches/cite_carter.png'
import afficheE from '../../images/projets/affiches/echecs.png'
import afficheMP from '../../images/projets/affiches/minis_projets.jpg'
import afficheW from '../../images/projets/affiches/test.png'

const ListeProjets = [
    {
        nom: 'Site Cité Carter',
        description: "Conception d'un site web dynamique afin de mettre en avant les différentes activitées mises en place par l'association ainsi que la programmation d'événements qui y sont organisés.",
        langages: [
            'HTML',
            'CSS',
            'PHP',
            'MySql',
            'JavaScript'
        ],
        lien: 'https://www.citecarter.com/',
        carte: cardCC,
        photos: [afficheCC],
        slug: 'cite_carter'
    },
    {
        nom: 'Jeu d\'échecs',
        description: "Réalisation d'un jeu d'échecs en duo, lors des études. Un mode multijoueur local ainsi qu'un mode contre l'ordinateur jouant aléatoirement ont été implémentés.",
        langages: ['Python'],
        lien: '',
        carte: cardE,
        photos: [afficheE],
        slug: 'echecs'
    },
    {
        nom: 'Mini-Projets',
        description: 'Petit projet de fin de formation BelieveMy mettant en place une page de météo géolocalisé + mini jeu de vitesse de frape au clavier.',
        langages: ['JavaScript'],
        lien: '',
        carte: cardMP,
        photos: [afficheMP],
        slug: 'mini_projets'
    },
    {
        nom: 'Poc-Weather',
        description: 'Petit projet test, mettant en place une page de météo géolocalisé avec système de cache et de distance.',
        langages: ['ReactJS', 'TypeScripte'],
        lien: '',
        carte: '',
        photos: [afficheW],
        slug: 'poc_weather'
    },
]

export default ListeProjets;