import React from "react";
import slugify from "slugify";
import { useParams } from "react-router-dom";
import Affiche from "./Affiche/Affiche";
import classes from './Projet.module.css'

export default function Projet(props) {
    const param = useParams()
    const la = props.lang.length > 1 ? "Langages :" : "Langage :"
    return (
        <div className={classes.projet}>
            <div style={{order: props.position === 'gauche' ? 0 : 1}}>
                <Affiche photos={props.photos} />
            </div>
            <div className={classes.informations}>
                <p>
                    <span className={classes.nom}>{props.nom}</span><br/>
                    {props.descri}<br/>
                    <span className={classes.langues}>{la}</span> &nbsp; {props.lang.join(', ')}<br/>
                </p>
                {
                    props.lien != ''
                    ? <a href={props.lien} className={classes.lien}>Visiter</a>
                    : null
                }
            </div>
        </div>
    )
}