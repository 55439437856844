import React from "react";
import classes from './Mentions.module.css'

export default function Mentions(params) {
    return (
        <div className={classes.mentions}>
            <p className="title" style={{textAlign: 'center'}}>Mention légales</p>
            <div className={classes.categorie}>
                <p className={classes.categorieTitle}>Éditeur :</p>
                <div className={classes.infos}>
                    <p>Identité : Lefebvre Aurélien</p>
                    <p>Coordonnées : par mail via le formulaire ou au numéro suivant 07.50.35.75.12</p>
                    <p>Adresse : 2 rue d'Hangest, 80310 Bourdon</p>
                </div>
            </div>
            <div className={classes.categorie}>
                <p className={classes.categorieTitle}>Hébergeur :</p>
                <div className={classes.infos}>
                    <p>Nom : LWS ( Ligne Web Service )</p>
                    <p>Coordonnées : 01 77 62 30 03</p>
                    <p>Adresse : 10, Rue Penthievre, 75008 Paris, France</p>
                </div>
            </div>
        </div>
    )
}