import React, { useEffect } from "react";
import classes from './Home.module.css'
import Presentation from "./Presentation/Presentation";
import Skills from "./Skills/Skills";
import Cartes from "./Cartes/Cartes";
import Contact from "./Contact/Contact";
import Premice from './Premice/Premice';

function Home(params) {
    useEffect(()=>{
        window.scrollTo(0, 0)
    },[])

    return (
        <>
            <Premice/>
 
            <div className={classes.container}>
                <section>
                    <Presentation/>
                </section>
                <section>
                    <Skills/>
                </section>
                <section>
                    <Cartes/>
                </section>
                <section style={{backgroundColor: '#0F292F'}}>
                    <Contact/>
                </section>
            </div>
        </>
    )
}

export default Home;