import React from "react";
import Progress from "./Progress/Progress";
import classes from './HardS.module.css'

export default function HardS(props) {
    const afficheSkills = props.hsk.map((skill, index) => {
        return (
            <div className={classes.Skill} key={index}>
                <p className={classes.description}>
                    <span>
                        <img src={skill.img} className={classes.logo}/>
                        {skill.nom}
                    </span>
                    <span style={{paddingTop: '35px'}}>
                        {skill.degres*10}%
                    </span>
                </p>
                <Progress pourcent={skill.degres} sl={skill.startLearn}/>
            </div>
        )
    })
    
    return (
        <div className={classes.HardS}>
            {afficheSkills}
        </div>
    )
}