import React from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import classes from './NavItem.module.css'

function NavItem(props) {
    return (
        <li className={classes.lien}>
            <NavLink to={props.lien} className={(match) => match?.isActive ? classes.actif : undefined}>
                {props.children}
            </NavLink>
        </li>
    )
}

export default NavItem;