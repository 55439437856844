import React, { useEffect } from "react";
import Navigation from "./Navigation/Navigation";

import classes from './Header.module.css'

function Header(params) {
    return (
        <header className={classes.header}>
            <div className={classes.flex}>
                <nav>
                    <Navigation/>
                </nav>
            </div>
        </header>
    )
}

export default  Header;